import VerticalRhythm from 'compass-vertical-rhythm';
import { css } from 'linaria';
import { colors } from './colors';
import { media } from './media';

export const vh = VerticalRhythm({ baseFontSize: '17px', baseLineHeight: 1.3 });

/**
 * Creates a rhythm for the FontSize `17px` and LineHeight: `1.3`
 * @param factor Multiplication Factor
 */
export const rh = (factor: number = 1): string => {
    return vh.rhythm(factor);
};

/**
 * Gets the percentage for a font size
 * @param size font size to ajuest eg. `14px`
 */
export const adjustFontSizeTo = (size: string): string => {
    return vh.adjustFontSizeTo(size).fontSize;
};


/**
 * Sets the link colors
 * @param normal color
 * @param hover color
 * @param active color
 * @param visited color
 * @param focus color
 */
export const linkColor = (
        normal: string | colors,
        hover: string | colors,
        active?: string | colors,
        visited?: string | colors,
        focus?: string | colors
    ): string => {
    return `
        color: ${normal};
        &:hover { color: ${hover}; }
        ${active && `
        &:active { color: ${active}; }
        `}
        ${visited && `
        &:visited { color: ${visited}; }
        `}
        ${focus && `
        &:focus { color: ${focus}; }
        `}
    `;
};


