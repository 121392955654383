import * as React from "react";
import { render } from 'react-dom';
import createStore from './store';
import { Provider } from 'unistore/react';
import App from './app';

// This is our global css, ony needs to be required on the client (webpack)
// the global.test is a check for the jest runner
require('./common/styles/global.css');

const baseconfig = {
    bindIp: process.env.BIND || ('127.0.0.1' as string),
    env: process.env.NODE_ENV || ('development' as 'development' | 'test' | 'production'),
    logLevel: process.env.LOG_LEVEL || ('error' as string)
}


const store = createStore({
    config: baseconfig,
    count: 0,
    images: []
})


render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById("app")
);
