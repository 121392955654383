import createStore from 'unistore';
import queryString from 'query-string';

/**
 * Store Actions interface
 */
export interface StoreActions  {
    getCredentialsFromUrl(): StoreActions & StoreState;
    bootstrap(): StoreActions & StoreState;
    getMerchantImages(): StoreActions & StoreState;
    postImage(): StoreActions & StoreState;
    moveImage(): StoreActions & StoreState;
    deleteImage(): StoreActions & StoreState;
}


/**
 * Store State interface
 */
export interface StoreState  {
    error?: {};
    config?: {
        translations: object,
        credentials: {
            hash: string,
            merchantId: string
        }
    };
    images?: any;
    merchant?: {
        id: string,
        name: string
    };
}

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export let actions = store => ({

    getCredentialsFromUrl: async ( state: any): Promise<StoreActions & StoreState> => {
        // Get the hash and merchantid
        const parsed = await queryString.parse(location.search);
        let credentials = {};
        if (!parsed.hash || !parsed.merchantid) {
            console.warn('hash and/or merchantid missing in querystring');
        } else {
            credentials = {
                hash: parsed.hash,
                merchantId: parsed.merchantid
            }
        }
        return {
            ...state,
            config: {
                ...state.config,
                credentials: credentials || {}
            }
        }
    },

    // Get the base config & translations
    bootstrap: async (state: any): Promise<StoreActions & StoreState> => {

        // Get the hash and merchantid
        const parsed = queryString.parse(location.search);
        let credentials = {};
        if (!parsed.hash || !parsed.merchantid) {
            console.warn('hash and/or merchantid missing in querystring');
        } else {
            credentials = {
                hash: parsed.hash,
                merchantId: parsed.merchantid
            }
        }

        // Get the config file
        try {
            if (process.env.NODE_ENV !== 'production') {
                await timeout(1000);
                const xhrSampleConfig = require('./samples/config.json');
                return {
                    ...state,
                    config: {
                        ...state.config,
                        ...xhrSampleConfig,
                        ...credentials
                    }
                };
            }

            const config = await fetch('/api/bootstrap', {
                method: 'GET',
            }).then(res => {
                return res.json()
            }).catch(err => {
                console.log('Bootstrap failed: ', err)
                return {}
            });

            return {
                ...state,
                config: {
                    ...state.config,
                    ...config,
                    ...credentials
                }
            };

        } catch (err) {
            console.log(err);
        }
    },


    // Fetch the list from the given endpoint
    getMerchantImages: async (state: any): Promise<StoreActions & StoreState> => {

        let merchantData;
        if (process.env.NODE_ENV !== 'production') {
            merchantData = require('./samples/GET.json');
        } else {
            if(state.config.merchantId) {
                merchantData = await fetch(`/api/merchant/${state.config.merchantId}?merchantid=${state.config.merchantId}&hash=${state.config.hash}`, {
                    method: 'GET',
                })
                .then(res => {
                    return res.json()
                })
                .catch(err => {
                    console.log('Error: ', err)
                    return {
                        pictures: [],
                        merchant: {
                            id: '',
                            name: ''
                        }
                    }
                })
            }
        }
        return {
            ...state,
            images: merchantData.pictures,
            merchant: {
                id: merchantData.id,
                name: merchantData.name
            }
        };
    },


    postImage: async (state: any, payload: {purpose: string, blob: Blob, name: string}): Promise<StoreActions & StoreState> => {

        if (process.env.NODE_ENV !== 'production') {
            await timeout(2000);
            return state;
        }

        const formData = new FormData();
        formData.append('purpose', payload.purpose);
        formData.append('blob', payload.blob);
        formData.append('name', payload.name);
        formData.append('hash', state.config.hash);
        formData.append('merchantid', state.config.merchantId);

        await fetch('/api/image/', {
            method: 'POST',
            body: formData
        });
        return state;
    },


    moveImage: async (state: any, payload: {id: string, index: string}): Promise<StoreActions & StoreState> => {

        if (process.env.NODE_ENV !== 'production') {
            await timeout(2000);
            return state;
        }

        const url = `/api/image/${payload.id}?index=${payload.index}&hash=${state.config.hash}&merchantid=${state.config.merchantId}`;
        const response = await (
            fetch(url, {
                method: 'PUT'
            })
            .then(res => {
                return res.json()
            })
            .catch(err => {
                console.log('Error: ', err)
                return {
                    pictures: state.images
                }
            })
        );

        return {
            ...state,
            images: response.pictures
        };

    },


    deleteImage: async (state: any, payload: {id: string}): Promise<StoreActions & StoreState> => {

        if (process.env.NODE_ENV !== 'production') {
            await timeout(2000);
            return state;
        }

        const url = `/api/image/${payload.id}?&hash=${state.config.hash}&merchantid=${state.config.merchantId}`;
        const response = await (
            fetch(url, {
                method: 'Delete'
            })
            .then(res => {
                return res.json()
            })
            .catch(err => {
                console.log('Error: ', err)
                return {
                    pictures: state.images
                }
            })
        )
        return {
            ...state,
            images: response.pictures
        };

    }
})

export default initialState => createStore(initialState)
