import * as React from "react";
import { connect } from 'unistore/react'
import { actions, StoreActions, StoreState } from './store';

import { TranslateProvider } from '@common/primitives/translations';
import VitraSpinner from '@common/primitives/spinners';
import VitraError from '@common/primitives/errors';
import VitraHeader from '@components/layout/header';
import VitraFooter from '@components/layout/footer';
import VitraContent from '@components/layout/content';
import VitraPage from '@components/layout/page';
import VitraCommunityUploader from '@components/community_uploader';


interface AppState {
    ready: boolean;
    error: any;
}
@(connect((state: any, props: any) => ({
    images: state.images || [],
    merchant: state.merchant || {},
    config: state.config || {}
})
, actions) as any)
export default class App extends React.Component<
    StoreActions & StoreState,
    AppState
> {


    public readonly state = {
        ready: false,
        error: false
    };


    async componentDidMount() {
        await this.props.getCredentialsFromUrl();
        await this.props.bootstrap();
        if (
            !this.props.config.credentials.hash ||
            !this.props.config.credentials.merchantId) {
                this.setState({
                    error: 'credentials',
                    ready: false
                })

        } else {
            await this.props.getMerchantImages();
            this.setState({
                ready: true
            });
        }
    }


    render() {
        return (
            <TranslateProvider translations={this.props.config.translations || {}}>
                {!this.state.ready && !this.state.error && (
                    <VitraPage>
                        <VitraSpinner/>
                    </VitraPage>
                )}
                {!this.state.ready && this.state.error && (
                    <VitraPage>
                        <VitraHeader {...this.props}/>
                        <VitraContent>
                            <VitraError translationId="error_configuration_url"/>
                        </VitraContent>
                        <VitraFooter/>
                    </VitraPage>
                )}
                {this.state.ready && (
                    <VitraPage>
                        <VitraHeader {...this.props}/>
                        <VitraContent>
                            <VitraCommunityUploader {...this.props}/>
                        </VitraContent>
                        <VitraFooter/>
                    </VitraPage>
                )}
            </TranslateProvider>
        );
    }
}
