import * as React from "react";
import { css } from 'linaria';
import { colors } from '../../../common/styles';

const styles = {
    wrapper: css`
        height: 100vh;
        position: relative;
    `,
    spinner: css`
        font-size: 48px;
        display: inline-block;
        width: 1em;
        height: 1em;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    `,
    stroke: css`
        position: absolute;
        left: 0.4629em;
        bottom: 0;
        width: 0.074em;
        height: 0.2777em;
        border-radius: 0.0555em;
        background-color: transparent;
        transform-origin: center -0.2222em;
        animation: spinner-fade 1s infinite linear;
        @keyframes spinner-fade {
            0% {
                background-color: ${colors.black};
            }
            100% {
                background-color: transparent;
            }
        }
    `
}


interface SpinnerProps {
    styles?: any;
}

export default class VitraSpinner extends React.Component<SpinnerProps, {}> {

    private strokeCount:number = 11;
    private durationSeconds:number = 1;
    private animationDelay:number = Math.round((this.durationSeconds / this.strokeCount) * 100) / 100;
    private animationRadius:number = Math.ceil(360 / this.strokeCount);

    private renderStrokes() {
        const html = [];
        for (let i = 0; i <= this.strokeCount; i++) {
            const animationStyle = {
                animationDelay: `${i * this.animationDelay}s`,
                transform: `rotate(${i * this.animationRadius}deg)`
            }
            html.push(<div style={animationStyle} className={styles.stroke}></div>)
        }
        return html;
    }

    public render(): JSX.Element {
        return (
            <div className={`vitra__spinner ${this.props.styles} ${styles.wrapper}`}>
                <div className={styles.spinner}>
                    {this.renderStrokes()}
                </div>
            </div>
        );
    }
}
