import * as React from "react";

interface LinkProp extends Link {
    id?: string;
    className?: string;
    role?: string;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export default class VitraLink extends React.Component<LinkProp, {}> {
    public render(): JSX.Element {

        const {
            href,
            role,
            name,
            title,
            target,
            onClick,
            className,
            id,
            children
        } = this.props;

        const hasChildren = React.Children.count(children) > 0;
        const elementToRender = hasChildren ? children : name;
        return (
            <a id={id}
                role={role}
                className={className}
                onClick={onClick} href={href} title={title || ''}>
                {elementToRender}
            </a>
        );
    }
}
