import * as React  from 'react';
import { css } from 'linaria';

const styles = {
    page: css`
        width: 100%;
        height: 100%;
        min-height: 100vh;
        position: relative;
        display: flex;
        flex-direction: column;
    `,
};

export default class VitraPage extends React.Component<{}, {}> {
    public render(): JSX.Element {
        return (
            <div className={`vitra__page ${styles.page}`}>
                {this.props.children}
            </div>
        );
    }
}
