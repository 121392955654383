export const formatLocale = (locale: string): string => {
    // 'en-US-u-VA-posix'.split('-').slice(0, 2)
    // ["en", "US"]
    return locale.split('-').slice(0, 2).map((chunk, index) => {
        // en[0]-US[1] <- chunk[1].toUpperCase()
        if (index !== 0 && chunk.length === 2) { return chunk.toUpperCase(); }
        return chunk;
    }).join('-');
};


export const getLocale = (locale?: any): string => {
    if (locale) { return locale; }
    const globalAny: any = global;

    if (globalAny.chrome && globalAny.chrome.runtime &&
        typeof globalAny.chrome.runtime.getManifest === 'function') {

        locale = globalAny.chrome.runtime.getManifest();
        if (locale && locale.current_locale) {
            return locale.current_locale;
        }
    }
    locale = (globalAny.navigator && (
        (globalAny.navigator.languages && globalAny.navigator.languages[0]) ||
        globalAny.navigator.language ||
        globalAny.navigator.userLanguage
    ));

    if (!locale && globalAny.navigator && globalAny.navigator.userAgent) {
        locale = globalAny.navigator.userAgent.match(/;.(\w+-\w+)/i);
        if (locale) { return locale[1]; }
    }

    if (!locale) {
        locale = (globalAny.clientInformation || Object.create(null)).language;
    }

    if (!locale) {
        if (globalAny.Intl && typeof globalAny.Intl.DateTimeFormat === 'function') {
            locale = globalAny.Intl.DateTimeFormat().resolvedOptions
                && globalAny.Intl.DateTimeFormat().resolvedOptions().locale;
        }
        if (!locale && ['LANG', 'LANGUAGE'].some(Object.hasOwnProperty, process.env)) {
            return (process.env.LANG || process.env.LANGUAGE || String())
                .replace(/[.:].*/, '')
                .replace('_', '-');
        }
    }

    try {
        if (console.table) {
            console.group('User Language Preferred:');
            console.table(window.navigator.languages)
            console.groupEnd();
            console.group('Client information:');
            console.log((globalAny.clientInformation || Object.create(null)).language);
            console.groupEnd();
            console.group('Intl Settings:')
            console.log(Intl.DateTimeFormat().resolvedOptions().locale)
            console.groupEnd();
        }
    } catch(e) {
        console.log('use a better browser')
    }

    const format = formatLocale(locale);
    return format.split('-')[0];
};
