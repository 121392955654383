import * as React from 'react';

const SvgArrowDown = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width="1em" height="1em" viewBox="0 0 81 44" {...props}>
        <path
            d="M.246 1.519L40.433 44l40.19-42.483A.9.9 0 0 0 79.316.281L40.431 41.387 1.551.282A.9.9 0 0 0 .246 1.519z"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgArrowDown;
