import * as React from 'react';

const SvgArrowUp = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width="1em" height="1em" viewBox="0 0 81 44" {...props}>
        <path
            d="M.246 42.481L40.433 0l40.19 42.483a.9.9 0 0 1-1.307 1.236L40.431 2.613 1.551 43.718a.9.9 0 0 1-1.305-1.237z"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgArrowUp;
