import * as React  from 'react';
import { containerCentered } from '@common/styles';
import { css } from 'linaria';

const styles = {
    content: css`
        flex-grow: 1;
        display: flex;
        justify-content: center;
    `,
};

export default class VitraContent extends React.Component<{}, {}> {
    public render(): JSX.Element {
        return (
            <div className={`vitra__content ${styles.content} ${containerCentered}`}>
                {this.props.children}
            </div>
        );
    }
}
