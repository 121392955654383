import * as React from "react";
import VitraLink from '../links/vitraLink';
import { colors, rh } from '../../../common/styles';
import { css, cx } from 'linaria';

interface ButtonProp {
    id?: string;
    link?: Link;
    type: 'primary' | 'secondary' | 'blackBorder' | 'whiteBorder';
    buttonType?: 'button' | 'submit';
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

const styles = {
    resetButton: css`
        appearance: none;
        display: inline-block;
        border: none;
        outline: none;
        margin: 0;
        text-decoration: none;
        cursor: pointer;
        text-align: center;
        white-space: nowrap;
    `,
    button: css`
        transition: background 100ms ease-in-out, transform 150ms ease;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        width: 100%;
        padding: ${rh(0.75)} ${rh(0.5)};
        color: ${colors.black};
        user-select: none;
        &:active {
            transform: scale(0.98);
        }
    `,
    buttonLink: css``,
    primary: css`
        color: #fff;
        background: ${colors.buttonPrimaryBackground};
        &:hover {
            background: ${colors.buttonPrimaryBackgroundHover};
            text-decoration: none;
            cursor: pointer;
        }
    `,
    secondary: css`
        color: ${colors.footerGrey};
    `,
    blackBorder: css`
        color: ${colors.black};
        background: transparent;
        border-bottom: 1px solid ${colors.black};
        border-top: 1px solid ${colors.black};
        &:hover {
            color: #fff;
            background: ${colors.black};
            text-decoration: none;
            cursor: pointer;
        }
    `,
    whiteBorder: css`
        color: #fff;
        background: transparent;
        border-bottom: 1px solid #fff;
        border-top: 1px solid #fff;
        &:hover {
            color: ${colors.black};
            background: #fff;
            text-decoration: none;
            cursor: pointer;
        }
    `
};

export default class VitraButton extends React.Component<ButtonProp, any> {
    public render(): JSX.Element {

        const {
            link,
            id,
            className,
            onClick,
            type,
            buttonType
        } = this.props;

        const buttonClass = cx(styles.button, (styles as any)[type], styles.resetButton, className);

        // If we have a link, we return a Link as a Button
        if (link) {
            return (
                <VitraLink
                    role="button"
                    id={id}
                    className={cx(buttonClass, styles.buttonLink)}
                    onClick={onClick}
                    {...link}
                >
                    {this.props.children}
                </VitraLink>
            );
        }

        return (
            <button
                id={id}
                type={buttonType || 'button'}
                onClick={onClick}
                className={cx(buttonClass)}
            >
                {this.props.children}
            </button>
        );
    }
}
