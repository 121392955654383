// tslint:disable max-classes-per-file

import * as React from 'react';
import { Dictionary } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import template from 'lodash/template';
import {getLocale} from '../../utils/language';


interface TranslateProviderProps {
    /**
     * The translations as dict
     */
    translations: Dictionary<string>;
}


interface TranslateProps {
    /**
     * The key of the Translations
     */
    id: string;

    /**
     * If the element has html
     * It get's wrapped in a div and set via innerHtml
     */
    html?: boolean;

    /**
     * A dict with variables to replace
     * for the text: Aktuell befinden sich <strong>{count} Produkte</strong> in Ihrer persönlichen Wunschliste
     * eg. `{count: 3}`
     */
    data?: Dictionary<string>;
}

const getTranslation = (translation: string, vars: Dictionary<string>) => {
    const compiled = template(translation, { interpolate: /{([\s\S]+?)}/g });
    return compiled(vars);
};


export class TranslateProvider extends React.PureComponent<TranslateProviderProps> {

    public constructor(props: any, context: any) {
        super(props);

        // Determine the locale
        context.bestGuessLocale = getLocale();
        console.log('buest guess client locale:', context.bestGuessLocale)

    }

    public getChildContext() {
        return {
            translations: (this.props.translations as any)
        };
    }

    public render(): JSX.Element {
        return <div style={{height: 'inherit'}}>{this.props.children}</div>;
    }
}


export default class Translate extends React.PureComponent<TranslateProps> {
    public constructor(props: TranslateProps, context: any) {
        super(props);
        this.context = context || {};

        // Determine the locale
        const bestGuessLocale = context.bestGuessLocale;
        if (!context.translations[bestGuessLocale]) {
            context.locale = 'en';
        } else {
            context.locale = bestGuessLocale;
        }
    }

    public render(): JSX.Element {
        const { id, html, data } = this.props;
        let text = this.context.translations[this.context.locale][id];

        // Interpolate the template string
        if (!isEmpty(data)) {
            text = getTranslation(text, data as Dictionary<string>);
        }

        if (!html) {
            return text ? text : `(translation not found): ${this.props.id}`;
        }
        return <div dangerouslySetInnerHTML={{ __html: text }} />;
    }
}

