/**
 * Sets the max-width for a container
 */
import { css } from 'linaria';
import { media } from './media';

export const containerCentered = css`
    position: relative;
    padding: 0 20px;
    max-width: 1420px;
    margin-left: auto;
    margin-right: auto;
    ${media.mobileNavigation} {
        padding: 0 15px;
    }
`;

