import IconArrowDown from '@common/primitives/icons/components/ArrowDown';
import IconArrowUp from '@common/primitives/icons/components/ArrowUp';
import VitraSpinner from '@common/primitives/spinners';
import Translate from '@common/primitives/translations';
import { cx } from 'linaria';
import times from 'lodash/times';
import * as React from 'react';
import { StoreActions, StoreState } from '../../store';
import * as ImageInterface from '../../types/image';
import { stylesSummary as styles } from './styles';

interface UploaderConfigInterface {
    id: number;
    viewport: {
        width: number;
        height: number;
    };
    boundary: {
        width: number;
        height: number;
    };
    resizeTo: {
        width: number;
        height: number;
    };
    purpose: string;
}

interface CommunityUploadSummaryProps {
    openUpload(options: UploaderConfigInterface): void;
    moveImage(data: object): StoreActions & StoreState;
    deleteImage(data: object): StoreActions & StoreState;
    images?: ImageInterface.Gallery;
    translations?: {};
}

interface CommunityUploadSummaryState {
    processing: any;
}

export default class CommunityUploadSummary extends React.Component<
    CommunityUploadSummaryProps,
    CommunityUploadSummaryState
> {
    private maxGalleryItems = 6;

    public readonly state = {
        processing: ''
    };

    private handleMove = async (data: { id: string; index: number }): Promise<void> => {
        if (this.state.processing) {
            return;
        }

        this.setState({
            processing: data.id
        });

        await this.props.moveImage(data);

        this.setState({
            processing: ''
        });
    };

    private handleDelete = async (data: { id: string }): Promise<void> => {
        if (this.state.processing) {
            return;
        }

        this.setState({
            processing: data.id
        });

        await this.props.deleteImage(data);

        this.setState({
            processing: ''
        });
    };

    public render(): JSX.Element {
        const thumbnailImage = this.props.images.find((i) => {
            try {
                return i.meta.purpose === 'thumbnail';
            } catch {
                console.warn('Data Structure Error');
            }
        });

        const galleryImages = this.props.images.filter((i) => {
            try {
                return i.meta.purpose === 'gallery';
            } catch {
                console.warn('Data Structure Error');
            }
        });

        return (
            <div className={`upload__summary ${styles.wrapper}`}>
                <h1 className={styles.h1}>
                    <Translate id="upload_picture" />
                </h1>
                <p className={styles.p}>
                    <Translate id="subtitle" />
                </p>
                <div className={`summary__main ${styles.summary__main} ${styles.flex}`}>
                    <div className={`main__explain ${styles.main__explain}`}>
                        <Translate id="image_note_overview" />
                    </div>
                    <div className={`main__item ${styles.main__item} ${styles.item}`}>
                        <div className={`item__move ${styles.item__move}`} />
                        <div
                            className={cx(
                                'item__image',
                                styles.item__image,
                                !thumbnailImage && styles['item__image--empty']
                            )}
                            style={{ backgroundImage: thumbnailImage ? `url(${thumbnailImage.src})` : 'none' }}
                            onClick={() => {
                                !thumbnailImage &&
                                    this.props.openUpload({
                                        purpose: 'thumbnail',
                                        id: 0,
                                        viewport: { width: 350, height: 350 },
                                        boundary: { width: 350, height: 350 },
                                        resizeTo: { width: 200, height: 200 }
                                    });
                            }}
                        >
                            {thumbnailImage && this.state.processing === thumbnailImage.id && (
                                <VitraSpinner styles={cx(styles.image__spinner)} />
                            )}
                        </div>
                        {thumbnailImage && (
                            <div
                                className={`item__delete ${styles.item__delete}`}
                                onClick={() => this.handleDelete({ id: thumbnailImage.id })}
                            >
                                <Translate id="delete" />
                            </div>
                        )}
                    </div>
                </div>
                <div className={`summary__list ${styles.flex}`}>
                    <div className={`list__explain ${styles.list__explain}`}>
                        <Translate id="image_note_details" />
                    </div>
                    <div className={`list__items ${styles.main__item}`}>
                        {galleryImages.map((galleryImage, idx) => (
                            <div className={`items__item ${styles.item}`}>
                                <div className={`item__move ${styles.item__move}`}>
                                    <div
                                        className={cx('move__up', idx === 0 && styles['move--disabled'])}
                                        /* MSP index for the gallery starts at 2, just to understand the (idx + 2) */
                                        onClick={() => this.handleMove({ id: galleryImage.id, index: idx + 2 - 1 })}
                                    >
                                        <IconArrowUp />
                                    </div>
                                    <div
                                        className={cx(
                                            'move__down',
                                            idx === galleryImages.length - 1 && styles['move--disabled']
                                        )}
                                        /* MSP index for the gallery starts at 2, just to understand the (idx + 2) */
                                        onClick={() => this.handleMove({ id: galleryImage.id, index: idx + 2 + 1 })}
                                    >
                                        <IconArrowDown />
                                    </div>
                                </div>
                                <div
                                    className={`items__image ${styles.item__image} ${styles['item__image--gallery']}`}
                                    style={{ backgroundImage: `url(${galleryImage.src})` }}
                                >
                                    {this.state.processing === galleryImage.id && (
                                        <VitraSpinner styles={cx(styles.image__spinner)} />
                                    )}
                                </div>
                                <div
                                    className={`item__delete ${styles.item__delete}`}
                                    onClick={() => this.handleDelete({ id: galleryImage.id })}
                                >
                                    <Translate id="delete" />
                                </div>
                            </div>
                        ))}
                        {galleryImages.length < this.maxGalleryItems &&
                            times(this.maxGalleryItems - galleryImages.length).map((v, i) => (
                                <div className={`items__item ${styles.item}`}>
                                    <div className={`item__move ${styles.item__move}`}></div>
                                    <div
                                        className={`items__image ${styles['item__image--empty']} ${styles['item__image--gallery']} ${styles.item__image}`}
                                        onClick={() => {
                                            this.props.openUpload({
                                                purpose: 'gallery',
                                                id: 0,
                                                viewport: { width: 640, height: 480 },
                                                boundary: { width: 640, height: 480 },
                                                resizeTo: { width: 1024, height: 768 }
                                            });
                                        }}
                                    ></div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        );
    }
}
