import * as React from 'react';
import { StoreActions, StoreState } from '../../store';
import VitraCommunityUploadPicture from './communityUploadPicture';
import VitraCommunityUploadSummary from './communityUploadSummary';

export default class CommunityUpload extends React.Component<StoreActions & StoreState, {}> {
    public readonly state = {
        uploadOpen: false,
        cropperOptions: {}
    };

    public openUpload = (options: any): void => {
        this.setState({
            cropperOptions: options,
            uploadOpen: true
        });
    };

    public closeUpload = (): void => {
        this.setState({ uploadOpen: false });
    };

    public render(): JSX.Element {
        return (
            <div className="vitra__community">
                {this.state.uploadOpen && (
                    <VitraCommunityUploadPicture
                        handleCancel={this.closeUpload}
                        handleSave={this.closeUpload}
                        cropperOptions={this.state.cropperOptions}
                        postImage={this.props.postImage}
                        getMerchantImages={this.props.getMerchantImages}
                    />
                )}
                {!this.state.uploadOpen && (
                    <VitraCommunityUploadSummary
                        moveImage={this.props.moveImage}
                        deleteImage={this.props.deleteImage}
                        openUpload={this.openUpload}
                        images={this.props.images}
                        translations={this.props.config.translations || {}}
                    />
                )}
            </div>
        );
    }
}
