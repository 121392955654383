import VitraButton from '@common/primitives/buttons';
import IconRotateLeft from '@common/primitives/icons/components/RotateLeft';
import IconRotateRight from '@common/primitives/icons/components/RotateRight';
import VitraSpinner from '@common/primitives/spinners';
import Translate from '@common/primitives/translations';
import { cx } from 'linaria';
import * as React from 'react';
import { StoreActions, StoreState } from '../../store';
import * as CroppieInterface from '../../types/croppie';
import { stylesUpload as styles } from './styles';

const canUseDOM = true;

interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}

interface CommunityUploadPictureProps {
    cropperOptions?: {
        viewport?: {
            width: number;
            height: number;
        };
        boundary?: {
            width: number;
            height: number;
        };
        resizeTo?: {
            width: number;
            height: number;
        };
        purpose?: string;
    };
    handleSave(): void;
    handleCancel(): void;
    postImage(data: object): StoreActions & StoreState;
    getMerchantImages(): StoreActions & StoreState;
}

interface CommunityUploadPictureState {
    errorTranslationId: string | boolean;
    imgSrc: string;
    fileSize: number;
    fileName: string;
    processing: boolean;
    cropperInstanceReady: boolean;
}

export default class CommunityUploadPicture extends React.Component<
    CommunityUploadPictureProps,
    CommunityUploadPictureState
> {
    public readonly state = {
        errorTranslationId: false,
        imgSrc: '',
        fileName: '',
        fileSize: 0,
        processing: false,
        cropperInstanceReady: false
    };

    private cropperInstance = {} as CroppieInterface;

    private readonly cropperOptions = {
        viewport: { width: 350, height: 350 },
        boundary: { width: 350, height: 350 }
    };

    /**
     * In order to crop big images we must ölimit the canvas for the cropping
     * This has nothing to do with the final size of the image which will be
     * submitted... Its only to handle big files during the cropping process
     * the final size is a what you see is what you get of the viewport
     */
    private createSizeLimitedCanvas(readerEventTargetResult: string): void {
        const image = new Image();
        image.onload = () => {
            const canvas: HTMLCanvasElement = document.createElement('canvas');
            const maxSize = 3024;
            let width = image.width;
            let height = image.height;

            if (width > height) {
                if (width > maxSize) {
                    height *= maxSize / width;
                    width = maxSize;
                }
            } else if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
            }

            canvas.width = width;
            canvas.height = height;

            const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;

            // Assure we have a white background (important for transparent pictures)
            ctx.beginPath();
            ctx.rect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = 'white';
            ctx.fill();

            ctx.drawImage(image, 0, 0, width, height);

            // lossless
            const dataUrl = canvas.toDataURL('image/jpeg', 1.0);
            this.setState({
                imgSrc: dataUrl
            });
            this.initCropper(dataUrl);
        };
        image.src = readerEventTargetResult;
    }

    private initCropper(dataUrl: string): void {
        if (canUseDOM) {
            this.setState({ cropperInstanceReady: true });

            // We must wait for setState to complete because otherwise Croppie looses the
            // reference... Preact doesnt perform any bookeeping of element references during render

            window.setTimeout(() => {
                const parent = document.getElementsByClassName('editor__cropper')[0];
                const croppieNode = document.createElement('div');
                parent.appendChild(croppieNode);

                const Croppie = require('croppie');
                const cropperContainer = croppieNode;
                const cropperInstance: CroppieInterface = new Croppie(cropperContainer, {
                    showZoomer: true,
                    enableOrientation: true,
                    ...this.props.cropperOptions
                });
                cropperInstance.bind({
                    url: dataUrl,
                    orientation: 1
                });

                this.cropperInstance = cropperInstance;
            }, 200);
        }
    }

    // Function to check image dimensions before POST
    private async checkImageDimensions(file: File, minWidth: number, minHeight: number): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const reader = new FileReader();

            // Read the file as a data URL
            reader.readAsDataURL(file);

            reader.onload = (event: ProgressEvent<FileReader>) => {
                // Create an image element
                const img = new Image();
                img.src = event.target.result as string;

                // Wait for the image to load
                img.onload = () => {
                    console.log('DIME', img.width, img.height);
                    // Check dimensions
                    if (img.width >= minWidth && img.height >= minHeight) {
                        resolve(true);
                    } else {
                        reject(new Error(`Image dimensions must be more than or equal to ${minWidth}x${minHeight}.`));
                    }
                };

                img.onerror = () => {
                    reject(new Error('Error loading the image.'));
                };
            };

            reader.onerror = () => {
                reject(new Error('Error reading the file.'));
            };
        });
    }

    private async handleFileInputChange(filesHandle: FileList | null) {
        const file = filesHandle![0];

        if (!file.type.match(/^image\//)) {
            const message = this.context.translations[this.context.locale]['error_file_type'];
            alert(message ? message : '(Translation Missing) error_file_type');
            return;
        }
        if (file.size / 1024 / 1024 > 16) {
            const message = this.context.translations[this.context.locale]['error_file_size'];
            alert(message ? message : '(Translation Missing) error_file_size');
            return;
        }

        await this.checkImageDimensions(file, 800, 800)
            .then(() => {
                this.setState({
                    fileSize: file.size,
                    fileName: file.name
                });

                const reader = new FileReader();
                reader.onload = (evt: any) => {
                    this.createSizeLimitedCanvas(evt.target!.result);
                };
                reader.readAsDataURL(file);
            })
            .catch((err) => {
                const message = this.context.translations[this.context.locale]['error_image_dimensions'];
                alert(message ? message : '(Translation Missing) error_image_dimensions');
            });
    }

    private handleSave = async (): Promise<void> => {
        try {
            this.setState({
                processing: true
            });

            const cropperResult: any = await this.cropperInstance.result({
                type: 'blob',
                size: this.props.cropperOptions.resizeTo,
                format: 'jpeg',
                quality: 1,
                circle: false
            });

            // The follwing code is only for testing purposes
            // will leave it here if we need it again
            // const image = document.createElement('img');
            // image.src = URL.createObjectURL(cropperResult);
            // image.width = this.props.cropperOptions.resizeTo!.width;
            // image.height = this.props.cropperOptions.resizeTo!.height;
            // document.body.appendChild(image);
            // return;

            if (this.props.handleSave) {
                await this.props.postImage({
                    name: this.state.fileName,
                    blob: cropperResult,
                    purpose: this.props.cropperOptions.purpose
                });
                await this.props.getMerchantImages();
                this.setState({
                    processing: false
                });
                this.props.handleSave();
                this.cropperInstance.destroy();
            } else {
                this.cropperInstance.destroy();
            }
        } catch (err) {
            console.log(err);
        }
    };

    private handleCancel = (): void => {
        if (this.cropperInstance && this.cropperInstance.destroy) {
            this.cropperInstance.destroy();
        }
        this.props.handleCancel();
    };

    public render(): JSX.Element {
        return (
            <div className={styles.wrapper}>
                <h1 className={styles.h1}>
                    <Translate id="upload_picture" />
                </h1>
                <p className={styles.p}>
                    <Translate id="subtitle" />
                </p>
                {!this.state.cropperInstanceReady && (
                    <div className={`upload__select ${styles.upload__select}`}>
                        <div className="select__button">
                            <input
                                id="file"
                                name="file"
                                type="file"
                                accept=".jpg,.png"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    return this.handleFileInputChange(e.target.files);
                                }}
                            />
                            <label htmlFor="file">
                                <Translate id="select_file" />
                            </label>
                        </div>
                        <VitraButton type="blackBorder" className="select__cancel" onClick={this.handleCancel}>
                            <Translate id="cancel" />
                        </VitraButton>
                    </div>
                )}

                <div className={`upload__editor ${styles.upload__editor}`}>
                    {this.state.cropperInstanceReady && (
                        <div
                            className="editor__rotate editor__rotate--left"
                            onClick={() => this.cropperInstance.rotate(90)}
                        >
                            <IconRotateLeft />
                        </div>
                    )}
                    <div className={`editor__cropper ${styles.editor__cropper}`}>
                        {this.state.processing && <VitraSpinner styles={cx(styles.editor__spinner)} />}
                    </div>
                    {this.state.cropperInstanceReady && (
                        <div
                            className="editor__rotate editor__rotate--right"
                            onClick={() => this.cropperInstance.rotate(-90)}
                        >
                            <IconRotateRight />
                        </div>
                    )}
                </div>

                {this.state.cropperInstanceReady && (
                    <div className={`upload__toolbar ${styles.upload__toolbar}`}>
                        {!this.state.processing && (
                            <VitraButton type="blackBorder" className="toolbar__send" onClick={this.handleSave}>
                                <Translate id="confirm" />
                            </VitraButton>
                        )}
                        {!this.state.processing && (
                            <VitraButton type="blackBorder" className="toolbar__abort" onClick={this.handleCancel}>
                                <Translate id="cancel" />
                            </VitraButton>
                        )}
                    </div>
                )}

                {this.state.fileSize !== 0 && (
                    <div className={`upload__meta ${styles.upload__meta}`}>
                        Originalgrösse: {`${Number(this.state.fileSize / 1024 / 1024).toFixed(2)} MB`}
                    </div>
                )}
            </div>
        );
    }
}
