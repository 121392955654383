import { css } from 'linaria';
import { colors, rh } from '../../common/styles';

// The styles for the summary list
const stylesSummary = {
    wrapper: css`
        margin-top: 70px;
        padding-bottom: 96px;
    `,
    h1: css`
        line-height: 1.3;
        font-weight: 400;
        color: #333;
        margin-bottom: 13px;
        margin-top: 0;
        font-size: 32px;
        text-align: center;
    `,
    p: css`
        max-width: 800px;
        font-size: 22px;
        font-weight: 100;
        margin: 0 auto 64px;
        text-align: center;
    `,
    flex: css`
        display: flex;
    `,
    summary__main: css`
        border-bottom: 1px solid ${colors.lightgrey};
        margin-bottom: 32px;
    `,
    main__explain: css`
        flex: 0 1 30%;
        font-size: 14px;
        text-align: left;
        padding-right: 64px;
    `,
    list__explain: css`
        flex: 0 1 30%;
        font-size: 14px;
        text-align: left;
        padding-right: 64px;
    `,
    main__item: css`
        flex: 0 1 70%;
    `,
    list__items: css`
        flex: 0 1 70%;
    `,
    item: css`
        display: flex;
        margin-bottom: 32px;
    `,
    item__image: css`
        background: ${colors.lightgrey};
        background-size: cover;
        height: 100px;
        width: 100px;
        position: relative;
    `,
    'item__image--gallery': css`
        width: 100px;
        height: 75px;
    `,
    'item__image--empty': css`
        &:hover {
            cursor: pointer;
        }
        &:before {
            content: '+';
            font-size: 32px;
            font-weight: 100;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    `,
    image__spinner: css`
        height: 100% !important;
    `,
    item__move: css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 0;
        margin-right: 32px;
        svg {
            width: 32px;
            height: 32px;
            fill: ${colors.black};
        }
        &:empty {
            padding-right: 32px;
        }
        > div {
            &:hover {
                cursor: pointer;
                svg {
                    fill: ${colors.primary};
                }
            }
        }
    `,
    'move--disabled': css`
        opacity: 0.2;
        pointer-events: none;
    `,
    item__delete: css`
        margin-left: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            cursor: pointer;
            color: ${colors.primary};
        }
    `
};

const stylesUpload = {
    wrapper: css`
        margin-top: 70px;
        text-align: center;
        padding-bottom: 96px;
    `,
    h1: css`
        line-height: 1.3;
        font-weight: 400;
        color: #333;
        margin-bottom: 13px;
        margin-top: 0;
        font-size: 32px;
        text-align: center;
    `,
    p: css`
        max-width: 800px;
        font-size: 22px;
        font-weight: 100;
        margin: 0 auto 32px;
        text-align: center;
    `,
    upload__select: css`
        display: flex;
        flex-wrap: wrap;
        flex-firection: column;
        .select__button {
            display: flex;
            justify-content: center;
            flex: 1 0 100%;
            white-space: nowrap;
            input {
                display: none;
            }
            label {
                width: 100%;
                display: inline-block;
                transition: background 100ms ease-in-out, transform 150ms ease;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 2px;
                text-transform: uppercase;
                padding: ${rh(0.75)} ${rh(0.5)};
                color: ${colors.black};
                &:active {
                    transform: scale(0.98);
                }
                border-top: 1px solid ${colors.buttonPrimaryBackground};
                border-bottom: 1px solid ${colors.buttonPrimaryBackground};
                &:hover {
                    color: white;
                    background: ${colors.black};
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
        .select__cancel {
            display: flex;
            justify-content: center;
            border-top: none;
            flex: 1 0 100%;
            width: auto;
        }
    `,
    upload__editor: css`
        display: flex;
        justify-content: center;
        align-items: center;
        .editor__rotate {
            svg {
                width: 44px;
                height: 38px;
                fill: $lightgrey;
            }
            position: relative;
            top: -24px;
            padding: 0 16px;
            &:hover {
                cursor: pointer;
                svg {
                    fill: $black;
                }
            }
        }
    `,
    upload__meta: css`
        font-size: 14px;
        text-align: center;
        margin-top: 32px;
        user-select: none;
    `,
    upload__toolbar: css`
        margin-top: 8px;
        display: block;
        button {
            margin: 0 auto;
            max-width: 350px;
            display: block;
            &:nth-child(2n) {
                margin-top: -1px;
            }
        }
    `,
    editor__spinner: css`
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 100%;
        margin-top: -26px;
    `,
    editor__cropper: css`
        position: relative;
        .croppie-container {
            width: 100%;
        }

        .croppie-container .cr-image {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            transform-origin: 0 0;
            max-height: none;
            max-width: none;
        }

        .croppie-container .cr-boundary {
            position: relative;
            overflow: hidden;
            margin: 0 auto;
            z-index: 1;
            width: 100%;
            height: 100%;
        }

        .croppie-container .cr-viewport,
        .croppie-container .cr-resizer {
            position: absolute;
            border: 2px solid #fff;
            margin: auto;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, 0.5);
            z-index: 0;
        }

        .croppie-container .cr-resizer {
            z-index: 2;
            box-shadow: none;
            pointer-events: none;
        }

        .croppie-container .cr-resizer-vertical,
        .croppie-container .cr-resizer-horisontal {
            position: absolute;
            pointer-events: all;
        }

        .croppie-container .cr-resizer-vertical::after,
        .croppie-container .cr-resizer-horisontal::after {
            display: block;
            position: absolute;
            box-sizing: border-box;
            border: 1px solid black;
            background: #fff;
            width: 10px;
            height: 10px;
            content: '';
        }

        .croppie-container .cr-resizer-vertical {
            bottom: -5px;
            cursor: row-resize;
            width: 100%;
            height: 10px;
        }

        .croppie-container .cr-resizer-vertical::after {
            left: 50%;
            margin-left: -5px;
        }

        .croppie-container .cr-resizer-horisontal {
            right: -5px;
            cursor: col-resize;
            width: 10px;
            height: 100%;
        }

        .croppie-container .cr-resizer-horisontal::after {
            top: 50%;
            margin-top: -5px;
        }

        .croppie-container .cr-original-image {
            display: none;
        }

        .croppie-container .cr-vp-circle {
            border-radius: 50%;
        }

        .croppie-container .cr-overlay {
            z-index: 1;
            position: absolute;
            cursor: move;
            touch-action: none;
        }

        .croppie-container .cr-slider-wrap {
            width: 75%;
            margin: 15px auto;
            text-align: center;
        }

        .croppie-result {
            position: relative;
            overflow: hidden;
        }

        .croppie-result img {
            position: absolute;
        }

        .croppie-container .cr-image,
        .croppie-container .cr-overlay,
        .croppie-container .cr-viewport {
            -webkit-transform: translateZ(0);
            -moz-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
        }

        /*************************************/
        /***** STYLING RANGE INPUT ***********/
        /*************************************/
        /*http://brennaobrien.com/blog/2014/05/style-input-type-range-in-every-browser.html */
        /*************************************/

        .cr-slider {
            -webkit-appearance: none;
            /*removes default webkit styles*/
            /*border: 1px solid white; */ /*fix for FF unable to apply focus style bug */
            width: 300px;
            /*required for proper track sizing in FF*/
            max-width: 100%;
            padding-top: 8px;
            padding-bottom: 8px;
            background-color: transparent;
        }

        .cr-slider::-webkit-slider-runnable-track {
            width: 100%;
            height: 3px;
            background: rgba(0, 0, 0, 0.5);
            border: 0;
            border-radius: 3px;
        }

        .cr-slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            border: none;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background: #ddd;
            margin-top: -6px;
        }

        .cr-slider:focus {
            outline: none;
        }
        /*
        .cr-slider:focus::-webkit-slider-runnable-track {
        background: #ccc;
        }
        */

        .cr-slider::-moz-range-track {
            width: 100%;
            height: 3px;
            background: rgba(0, 0, 0, 0.5);
            border: 0;
            border-radius: 3px;
        }

        .cr-slider::-moz-range-thumb {
            border: none;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background: #ddd;
            margin-top: -6px;
        }

        /*hide the outline behind the border*/
        .cr-slider:-moz-focusring {
            outline: 1px solid white;
            outline-offset: -1px;
        }

        .cr-slider::-ms-track {
            width: 100%;
            height: 5px;
            background: transparent;
            /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
            border-color: transparent; /*leave room for the larger thumb to overflow with a transparent border */
            border-width: 6px 0;
            color: transparent; /*remove default tick marks*/
        }
        .cr-slider::-ms-fill-lower {
            background: rgba(0, 0, 0, 0.5);
            border-radius: 10px;
        }
        .cr-slider::-ms-fill-upper {
            background: rgba(0, 0, 0, 0.5);
            border-radius: 10px;
        }
        .cr-slider::-ms-thumb {
            border: none;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background: #ddd;
            margin-top: 1px;
        }
        .cr-slider:focus::-ms-fill-lower {
            background: rgba(0, 0, 0, 0.5);
        }
        .cr-slider:focus::-ms-fill-upper {
            background: rgba(0, 0, 0, 0.5);
        }
        /*******************************************/

        /***********************************/
        /* Rotation Tools */
        /***********************************/
        .cr-rotate-controls {
            position: absolute;
            bottom: 5px;
            left: 5px;
            z-index: 1;
        }
        .cr-rotate-controls button {
            border: 0;
            background: none;
        }
        .cr-rotate-controls i:before {
            display: inline-block;
            font-style: normal;
            font-weight: 900;
            font-size: 22px;
        }
        .cr-rotate-l i:before {
            content: '↺';
        }
        .cr-rotate-r i:before {
            content: '↻';
        }
    `
};

export { stylesSummary, stylesUpload };
