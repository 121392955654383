import * as React  from 'react';
import VitraLogo from '@common/primitives/icons/components/Logo';
import Translate from '@common/primitives/translations';
import { colors, containerCentered, rh } from '@common/styles';
import { css } from 'linaria';
import { StoreActions, StoreState } from '../../../store';


const styles = {
    headerSpacer: css`
        padding-top: ${rh(1.5)};
        padding-bottom: ${rh(1)};
        border-bottom: 1px solid ${colors.lightgrey};
        display: flex;
        align-items: center;
        width: 100%;
    `,
    headerLogo: css`
        margin-top: auto;
    `,
    headerTitle: css`
        font-size: 16px;
        padding-left: 16px;
        margin-top: auto;
    `,
    headerMeta: css`
        text-align: right;
        font-size: 16px;
        padding-left: 16px;
        margin-top: auto;
        margin-left: auto;
    `
};

export default class VitraHeader extends React.Component<
    StoreActions & StoreState,
    {}
> {
    public render(): JSX.Element {
        return (
            <div className={`vitra__header ${styles.headerSpacer} ${containerCentered}`}>
                <div className={`header__logo ${styles.headerLogo}`}>
                    <VitraLogo />
                </div>
                <div className={`header__title ${styles.headerTitle}`}>
                    <Translate id="common_headline"/>
                </div>
                {this.props.merchant.id && (
                    <div className={`header__meta ${styles.headerMeta}`}>
                        <div className="meta__id">
                            <Translate id="retailer_id"/>&nbsp;{this.props.merchant.id}
                        </div>
                        <div className="meta__name">
                            <Translate id="retailer_name"/>&nbsp;{this.props.merchant.name}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
