export enum colors {
    black= '#333',
    primary= '#F78181',
    lightgrey= '#CCC',
    silver= '#f5f5f5',
    textGrey= '#999999',
    blue= '#a7daf6',
    error= '#ff6564',
    footerGrey= '#E6E6E6',
    infoBanner= '#f6f3eb',
    buttonPrimaryBackground= '#333',
    buttonPrimaryBackgroundHover= '#666'
}
