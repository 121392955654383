import * as React  from 'react';
import Translate from '@common/primitives/translations';
import { colors, containerCentered, rh } from '@common/styles';
import { css } from 'linaria';

const styles = {
    footer: css`
        padding-top: ${rh(1.5)};
        padding-bottom: ${rh(1.5)};
        line-height: ${rh(1.5)};
        background-color: ${colors.footerGrey};
        text-align: center;
        font-size: 12px;
        width: 100%;
        .footer__contact {
            font-size: 14px;
            margin-bottom: ${rh(1)};
            .contact__headline {
                font-weight: 700;
            }
        }
        .footer__copyright {
            margin-top: ${rh(2)};
            margin-bottom: ${rh(1)};
        }
    `,
};

export default class VitraFooter extends React.Component<{}, {}> {
    public render(): JSX.Element {

        const nowYear = new Date().getFullYear();

        return (
            <div className={`vitra__footer ${styles.footer}`}>
                <div className={containerCentered}>
                    <div className="footer__contact">
                        <div className="contact__headline">
                            <Translate id="contact_headline"/>
                        </div>
                        <div className="contact__sub">
                            <Translate id="contact_note"/>
                        </div>
                    </div>
                    <div className="footer__copyright">
                        COPYRIGHT ©{{nowYear}} VITRA INTERNATIONAL AG. ALL RIGHTS RESERVED
                    </div>
                </div>
            </div>
        );
    }
}
