import * as React from "react";
import { css } from 'linaria';
import { colors } from '../../../common/styles';
import Translate from '@common/primitives/translations';

const styles = {
    wrapper: css`
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    message: css`
        min-width: 300px;
        min-height: 140px;
        padding: 16px;
        background: ${colors.black};
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    `
}

interface VitraErrorProps {
    translationId?: string;
}

export default class VitraError extends React.Component<VitraErrorProps> {


    public render(): JSX.Element {
        return (
            <div className={`vitra__error ${styles.wrapper}`}>
                <div className={`error__message ${styles.message}`}>
                    {this.props.translationId && (
                        <Translate id={this.props.translationId}/>
                    )}
                    {!this.props.translationId && (
                        <span>Unknown Error</span>
                    )}
                </div>
            </div>
        );
    }
}
